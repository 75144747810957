<template>
  <div>
    <el-main>
      <head-layout
        :head-btn-options="headBtnOptions"
        head-title="基本信息"
        @head-cancel="headCancel"
      ></head-layout>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm.sync="dataForm"
      ></form-layout>
      <div>
        <head-layout
          head-title="培训内容"
        ></head-layout>
        <el-table
          :data="tableData"
          border
          class="margin-table"
          style="width: 96%; margin-bottom: 40px; margin-top: 10px;">
          <el-table-column
            prop="trainingVideo"
            align="center"
            label="培训视频">
            <template slot-scope="scope">
              <a href="javascript:void(0);" class="blue-underline"
                 @click="rowTrainingVideo(scope.row)">{{ scope.row.trainingVideo }}</a>
            </template>
          </el-table-column>
          <el-table-column
            prop="courseware"
            align="center"
            label="课件">
            <template slot-scope="scope">
              <a href="javascript:void(0);" class="blue-underline"
                 @click="rowCourseware(scope.row)">{{ scope.row.courseware }}</a>
            </template>
          </el-table-column>
          <el-table-column
            prop="questions"
            align="center"
            label="试题"
          >
            <template slot-scope="scope">
              <a href="javascript:void(0);" class="blue-underline"
                 @click="rowQuestions(scope.row)">{{ scope.row.questions }}</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <head-layout
          @head-add-tabs="headAddThirdPartyPersonnel"
          :head-btn-options="headBtnOptions2"
          head-title="培训人员"
        ></head-layout>
        <el-table
          :data="tableData2"
          border
          class="margin-table"
          style="width: 96%; margin-bottom: 40px; margin-top: 10px;">
          <el-table-column
            prop="index"
            align="center"
            width="80"
            label="序号">
          </el-table-column>
          <el-table-column
            prop="name"
            align="center"
            label="姓名">
          </el-table-column>
          <el-table-column
            prop="identityCard"
            align="center"
            label="身份证号">
          </el-table-column>
          <el-table-column
            prop="jobType"
            align="center"
            label="工种">
          </el-table-column>
          <el-table-column
            prop="participatingUnits"
            align="center"
            label="参训单位"
            :formatter="formatUnitName">
          </el-table-column>
          <el-table-column
            prop="isExam"
            align="center"
            width="100"
            label="是否考试"
            :formatter="formatIsExam">
          </el-table-column>
          <el-table-column
            prop="isQualified"
            align="center"
            width="100"
            label="是否合格"
            :formatter="formatIsExam">
          </el-table-column>
          <el-table-column
            prop="fraction"
            align="center"
            width="100"
            label="分数">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120" align="center">
            <template #default="scope">
              <el-button size="small" @click="handleClick(scope.row)" type="text"
              >编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <head-layout
        @head-update-tabs="headAddImages"
        :head-btn-options="headBtnOptions3"
        head-title="现场照片"
      ></head-layout>
      <div class="pictureBox">
        <avue-form :option="option" v-model="form" :upload-preview="rowView" :upload-after="uploadAfter" :upload-delete="uploadDelete"></avue-form>
      </div>
      <!-- 文件输入隐藏域，用于选择图片 -->
<!--      <input
        type="file"
        ref="imageInput"
        accept="image/*"
        @change="onFileChange"
        style="display: none;"
      />
      <div>
        <p>
          <img style="width:200px;margin-right:20px"
               v-for="(d, index) of imageData"
               :src="d.thumbUrl"
               :key="index"
               @click="openPreview(index)">
        </p>
      </div>-->
    </el-main>
    <addThirduser ref='addThirduser' @callback="getPrjthirduserPage"></addThirduser>
    <el-dialog
      v-dialog-drag
      title="培训人员编辑"
      :modal="true"
      :modal-append-to-body="false"
      :visible.sync="updatePersonnelDialog"
      width="80%"
      class="paperDialog"
      @close="closeUpdatePersonnelDialog"
    >
      <el-container>
        <el-main>
          <form-layout
            ref="personnelFormLayout"
            :column="personnelFormColumn"
            :dataForm.sync="personnelForm"
          ></form-layout>
        </el-main>
      </el-container>
      <span slot="footer" class="dialogBtn">
        <el-button size="medium" type="primary" @click="confirm">确认</el-button>
        <el-button size="medium" @click="closeUpdatePersonnelDialog">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <video ref="video" id="myVideo" :src="videoUrl" crossOrigin="anonymous" style="display: none"></video>
    <canvas id="myCanvas" style="display: none" crossOrigin="anonymous"></canvas>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout.vue";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import {
  getThreeTrainImage,
  nowTrainDeleteImage,
  nowTrainSaveImage,
  trainGroupScenDetail,
} from "@/api/onTheJobTraining/training";
import {
  getDetail,
  addPersonnel,
  updatePersonnel,
  getIsQualifiedDicData,
  substance,
  getByProjectName,
  prjthirdcompanyPage,
  saveImage, calculateQualification
} from "@/api/training/recording";
import CommonTree from "@/views/components/com_tree/index.vue";
import addThirduser from "./components/addThirduser.vue";
import {prjthirduserPage} from "@/api/safe/itemList";
import website from "@/config/website";
import {Base64} from "js-base64";
import {putFile} from "@/api/system/user";


export default {
  components: {
    addThirduser,
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      fileUrl: "",
      showFileDialog: false,
      videdata: [],
      coursewareData: [],
      testQuestionsData: [],
      prjthirdcompanyList: [],
      prjDate: {},
      imageFile: '',
      previewUrl: '',
      KnowledgeAllDate: [],
      dicData: [],
      personnelForm: {},
      updatePersonnelDialog: false,
      roleBox: false,
      imageData: [],
      dataForm: {},
      tableData: [],
      tableData2: [],
      map: new Map(),
      videoUrl: "",
      cover: "",
      form: {
        imgUrl:[]
      },
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth:0,
        column: [{
          label: '',
          prop: 'imgUrl',
          listType: 'picture-card',
          type: 'upload',
          fileType: 'img,video',
          span: 24,
          limit:9,
          propsHttp: {
            res: 'data',
            url: 'link',
          },
          click(r,s){
            if(r.value.length>=9){
              return this.$message.warning("最多支持上传9张图片或者视频！")
            }
          },
          action: 'api/sinoma-resource/oss/endpoint/put-file',
        }]
      }
    }
  },
  computed: {
    personnelFormColumn() {
      return [
        {
          label: "参训单位",
          prop: "participatingUnits",
          labelWidth: 120,
          placeholder: "请选择参训单位",
          dicData: this.prjthirdcompanyList,
          type: 'select',
          readonly: true,
          span: 8,
        },
        {
          label: "是否考试",
          prop: "isExam",
          labelWidth: 120,
          placeholder: "请选择是否考试",
          type: 'select',
          dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dataType: "number",
          readonly: true,
          span: 8,
        },
        {
          label: "是否合格",
          prop: "isQualified",
          readonly: true,
          labelWidth: 120,
          type: 'select',
          dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dataType: "number",
          placeholder: "请选择是否合格",
          span: 8,
        },
        {
          label: "分数",
          prop: "fraction",
          labelWidth: 120,
          placeholder: "请输入分数值",
          span: 8,
          type: "number",
          rules: [
            {
              validator: (rule, value, callback) => {
                const num = parseInt(value);
                if (num <= 0 || num > 999) {
                  callback(new Error("请输入1-999之间的正数"));
                } else {
                  callback();
                }
              },
              trigger: "blur"
            }
          ]
        }
      ]
    },
    formColumn() {
      return [
        {
          label: "培训名称",
          prop: "trainingName",
          labelWidth: 120,
          readonly: true,
          span: 8,
        },
        {
          label: "培训场景",
          prop: "trainingScenarios",
          readonly: true,
          labelWidth: 120,
          span: 8,
        },
        {
          label: "培训时间",
          prop: "trainingTime",
          readonly: true,
          labelWidth: 120,
          span: 8,
        },
        {
          label: "受训人数",
          prop: "traineesNumber",
          labelWidth: 120,
          readonly: true,
          span: 8,
        },
        {
          label: "发起人",
          prop: "sponsor",
          readonly: true,
          labelWidth: 120,
          span: 8,
        },
        {
          label: "项目",
          prop: "project",
          readonly: true,
          labelWidth: 120,
          span: 8,
        },
        {
          label: "课程简介",
          prop: "remark",
          readonly: true,
          labelWidth: 120,
          placeholder: "请输入描述",
          sortable: true,
          type: "textarea",
          overHidden: true,
          span: 24,
          minRows: "2",
          maxRows: "4",
          maxlength: 500,
          showWordLimit: true,
        },
      ]
    },
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
        });
      return buttonBtn;
    },
    headBtnOptions2() {
      let buttonBtn = [];
      if (this.type != 'view') {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-add-tabs",
            type: "button",
            btnOptType: 'primary',
          });
      }
      return buttonBtn;
    },
    headBtnOptions3() {
      let buttonBtn = [];
      /*buttonBtn.push(
        {
          label: '上传',
          emit: "head-update-tabs",
          type: "button",
          btnOptType: 'primary',
        });*/
      return buttonBtn;
    },
  },
  methods: {
    base64ToFile(base64Data, filename) {
      // 将base64的数据部分提取出来
      const parts = base64Data.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);

      // 将原始数据转换为Uint8Array
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      // 使用Blob和提取出的数据内容创建一个新的Blob对象
      const blob = new Blob([uInt8Array], {type: contentType});

      // 创建一个指向Blob对象的URL，并使用这个URL创建一个File对象
      const blobUrl = URL.createObjectURL(blob);
      const file = new File([blob], filename, {type: contentType});

      // 返回File对象
      return file;
    },
    formatUnitName(row) {
      const matchedUnit = this.prjthirdcompanyList.find(item => item.value === row.participatingUnits);
      return matchedUnit ? matchedUnit.label : row.participatingUnits; // 如果找到匹配项则返回标签，否则返回默认值
    },
    getTrainContent() {
      trainGroupScenDetail(this.dataForm.siteTrainingId).then(res => {
        this.videdata = res.data.data.kmInfoVideoList
        this.coursewareData = res.data.data.kmInfoCoursewareList
        this.testQuestionsData = res.data.data.eduPaperList
      })
    },
    headAddImages() {
      this.$refs.imageInput.click();
    },
    onFileChange(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        // 生成预览图片URL
        this.previewUrl = URL.createObjectURL(selectedFile);
        // 将文件保存到data中，以便后续提交到服务器
        this.imageFile = selectedFile;

        this.uploadImageToServer(this.imageFile);
      }
    },
    async uploadImageToServer(file) {
      try {
        // 创建FormData对象
        const formData = new FormData();
        formData.append('file', file, file.name);

        // 发送POST请求
        const response = await axios.post(
          '/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        if (response.status === 200) {
          response.data.data.link
          let data = {
            recordingId: this.dataForm.id,
            image: response.data.data.link
          }
          saveImage(data).then((res) => {
            if (res.data.code === 200) {
              this.$message.success('图片传成功！')
              this.detail(this.dataForm.id)
            }
          })
        } else {
          this.$message.warning('图片上传失败:' + response.statusText)
        }

      } catch (error) {
        console.error('图片上传过程中发生错误:', error);
      }
    },
    rowTrainingVideo(row) {
      if (this.videdata.length > 0) {
        this.videdata.forEach((e) => {
          if (e.id === row.trainingVideoId) {
            row = e
          }
        })
        this.fileUrl = process.env.VUE_APP_BASE_KKFILEURL + "?url=" +
          encodeURIComponent(Base64.encode(row.fileCover));
        this.showFileDialog = true;
        //exls表格预览隐藏打印按钮
        if (process.env.NODE_ENV === "production") {
          const iframe = this.$refs.fileIframe;
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;

          // 在 iframe 内部隐藏元素
          if (iframeDocument) {
            const elementToHide = iframeDocument.getElementById("button-area");
            if (elementToHide) {
              elementToHide.style.display = "none";
            }
          }
        }
      }
    },
    rowCourseware(row) {
      if (this.coursewareData.length > 0) {
        this.coursewareData.forEach((e) => {
          if (e.id === row.coursewareId) {
            row = e
          }
        })
        this.fileUrl = process.env.VUE_APP_BASE_KKFILEURL + "?url=" +
          encodeURIComponent(Base64.encode(row.fileCover));
        this.showFileDialog = true;
        //exls表格预览隐藏打印按钮
        if (process.env.NODE_ENV === "production") {
          const iframe = this.$refs.fileIframe;
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;

          // 在 iframe 内部隐藏元素
          if (iframeDocument) {
            const elementToHide = iframeDocument.getElementById("button-area");
            if (elementToHide) {
              elementToHide.style.display = "none";
            }
          }
        }
      }
    },
    rowQuestions(row) {
      if (this.testQuestionsData.length > 0) {
        this.testQuestionsData.forEach((e) => {
          if (e.id === row.questionsId) {
            this.$router.push({
              path: '/training/paper/edupaperView',
              query: {
                row: JSON.stringify(e),
                type: 'view',
              }
            });
          }
        })
      }
    },
    formatIsExam(row, column) {
      const value = row[column.property];
      const dict = this.dicData.reduce((acc, current) => {
        acc[current.dictKey] = current.dictValue;
        return acc;
      }, {});
      return dict[value];
    },
    headAddThirdPartyPersonnel() {
      this.$refs.addThirduser.init('add', this.dataForm.id, '')
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    rowView(file, column, done) {
      let url = this.map.get(file.name);
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL + "?url=" +
        encodeURIComponent(Base64.encode(url));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    openPreview(index = 0) {
      if (this.imageData) {
        let data = this.imageData[index]
        let extension = data.url.split('.').pop();
        if (extension == 'mp4' ||
          extension == 'avi' ||
          extension == 'mkv' ||
          extension == 'mov' ||
          extension == 'wmv' ||
          extension == 'flv' ||
          extension == 'mpeg') {
          this.fileUrl = process.env.VUE_APP_BASE_KKFILEURL + "?url=" +
            encodeURIComponent(Base64.encode(data.url));
          this.showFileDialog = true;
          if (process.env.NODE_ENV === "production") {
            const iframe = this.$refs.fileIframe;
            const iframeDocument =
              iframe.contentDocument || iframe.contentWindow.document;

            // 在 iframe 内部隐藏元素
            if (iframeDocument) {
              const elementToHide = iframeDocument.getElementById("button-area");
              if (elementToHide) {
                elementToHide.style.display = "none";
              }
            }
          }
        } else {
          this.$ImagePreview(this.imageData, index, {
            closeOnClickModal: true,
            interval: 3000,
            click: (data, index) => {
              this.$message.success('打开预览' + index)
            },
            beforeClose: () => {
              this.$message.success('已关闭预览')
            }
          });
        }
      }
    },
    uploadAfter(res, done, loading, column) {
      this.videoUrl = res.link
      let that = this;
      that.$nextTick(() => {
        let extension = that.videoUrl.split('.').pop();
        if (extension == 'mp4' ||
          extension == 'avi' ||
          extension == 'mkv' ||
          extension == 'mov' ||
          extension == 'wmv' ||
          extension == 'flv' ||
          extension == 'mpeg') {
          //如果上传的是视频,需要截取封面
          const video = document.getElementById('myVideo');
          const canvas = document.getElementById('myCanvas');
          const ctx = canvas.getContext('2d');
          video.addEventListener('loadeddata', function () {
            video.currentTime = 2;
            setTimeout(() => {
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
              const imageURL = canvas.toDataURL('image/png');
              const formData = new FormData();
              // console.log("进行输出-----------1",imageURL)
              formData.append('file', that.base64ToFile(imageURL, Date.now()+'.png'));
              putFile(formData).then((res) => {
                that.cover = res.data.data.link
                nowTrainSaveImage({
                  image:that.videoUrl,
                  recordingId: that.dataForm.id,
                  cover: that.cover
                }).then((res) =>{
                  that.$message.success("上传成功")
                  that.detail(that.dataForm.id)
                  done();
                })
              })
            }, 2000)
          });
        } else {
          nowTrainSaveImage({
            image:that.videoUrl,
            recordingId: that.dataForm.id,
            cover: ""
          }).then((res) =>{
            that.$message.success("上传成功")
            that.detail(that.dataForm.id)
            done();
          })
        }
      })
    },
    uploadDelete(file, column) {
      let boolean = file.name.indexOf("upload") != -1;
      if (!boolean) {
        nowTrainDeleteImage(file.name).then((res) =>{
          this.$message.success("删除成功");
        })
      }
    },
    detail(id) {
      this.tableData = [];
      this.tableData2 = [];
      this.imageData = [];
      getDetail(id).then((res) => {
        this.dataForm = res.data.data
        // 处理 trainingSubstanceList
        let videoList = []
        let coursewareList = []
        let questionsList = []
        res.data.data.trainingSubstanceList.forEach((e) => {
          if (e.type === 1) {
            videoList.push(e)
          }
          if (e.type === 2) {
            coursewareList.push(e)
          }
          if (e.type === 3) {
            questionsList.push(e)
          }
        })
        let maxLength = Math.max(videoList.length, coursewareList.length, questionsList.length);
        for (let i = 0; i < maxLength; i++) {
          let rowData = {
            trainingVideo: (videoList[i] && videoList[i].name) || "",
            trainingVideoId: (videoList[i] && videoList[i].fileId) || "",
            courseware: (coursewareList[i] && coursewareList[i].name) || "",
            coursewareId: (coursewareList[i] && coursewareList[i].fileId) || "",
            questions: (questionsList[i] && questionsList[i].name) || "",
            questionsId: (questionsList[i] && questionsList[i].fileId) || ""
          }
          this.tableData.push(rowData);
        }

        // 处理 trainingPersonnelList
        let i = 1;
        this.tableData2 = res.data.data.trainingPersonnelList.map((e) => {
          return {
            index: i++,
            ...e
          };
        });
        this.form.imgUrl = [];
        // 处理 trainingImageList
        this.imageData = res.data.data.trainingImageList.map((e) => {
          let extension = e.image.split('.').pop();
          this.map.set(e.id, e.image)
          if(e.cover){
            //视频默认使用的图片
            this.form.imgUrl.push({
              label :e.id,
              value : e.cover,
            })
          }else {
            this.form.imgUrl.push({
              label :e.id,
              value : e.image,
            })
          }
        });
      }).then(() => {
        if (this.dataForm.siteTrainingId) {
          this.getTrainContent()
        }
        // 获取项目数据
        getByProjectName({
          "prjName": this.dataForm.project
          // "prjName": '测试'
        }).then((res) => {
          if (res.data.code == 200) {
            this.prjDate = res.data.data;
          }
        }).then(() => {
          this.prjthirdcompanyList = []
          // 获取项目相关单位
          if (this.prjDate.id) {
            prjthirdcompanyPage({
              "prjId": this.prjDate.id
            }).then((res) => {
              res.data.data.forEach((e) => {
                let data = {}
                data.label = e.companyName
                data.value = e.id
                this.prjthirdcompanyList.push(data)
              })
            })
          }
        })
      })
    },
    confirm() {
      this.$refs.personnelFormLayout.$refs.form.validate((valid) => {
        if (valid) {
          updatePersonnel(this.personnelForm).then((res) => {
            if (res.data.code === 200) {
              this.$message.success("操作成功！");
            }
          }).then(() => {
            this.$refs.personnelFormLayout.$refs.form.allDisabled = false
            this.updatePersonnelDialog = false
            this.detail(this.dataForm.id)
          })
        }
      })
    },
    handleClick(row) {
      if (row.isExam === -1) {
        row.isExam = null
      }
      if (row.isQualified === -1) {
        row.isQualified = null
      }
      this.personnelForm = row
      this.updatePersonnelDialog = true
    },
    closeUpdatePersonnelDialog() {
      this.personnelForm = {}
      this.updatePersonnelDialog = false
    },
    getPrjthirduserPage(personnelData) {
      let row = {}
      let trainingPersonnelList = []
      personnelData.name = personnelData.userName
      personnelData.identityCard = personnelData.idCoding
      personnelData.jobType = personnelData.post
      trainingPersonnelList.push(personnelData)
      row.id = this.dataForm.id
      row.trainingPersonnelList = trainingPersonnelList
      addPersonnel(row).then((res) => {
        if (res.data.code == 200) {
          this.detail(this.dataForm.id)
        }
      })
    },
  },
  mounted() {
    getIsQualifiedDicData().then((res) => {
      this.dicData = res.data.data;
    })
    this.dataForm.id = this.$route.query.id;
    this.detail(this.dataForm.id);
  }
}
</script>

<style scoped>
.margin-table {
  margin-left: 30px;
  margin-right: 30px;
}

/deep/ .el-table th div.cell {
  background-color: #e8e8e8; /* 灰色背景色 */
  font-size: 16px;
  height: 30px;
  line-height: 30px; /* 设置行高使文字垂直居中 */
}

.blue-underline {
  text-decoration: underline;
  color: #409EFF;
  cursor: pointer; /* 添加鼠标悬停时的指针样式 */
}
</style>
